import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser}>
    <div className={styles.descUser}>
      <img
        src="/images/about/sps/sps_logo.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
      Dystopia and dread in black and white from Pangrati, Athens. 
      Tragicomic in the result, sps' illustrations are a grim view on an urban life.
      </p>
      <div className={styles.linkUser}>
        <h3 style={{ marginLeft: "0px", marginBottom: "0px" }}>
          <a href="mailto:sps.91@hotmail.com">CONTACT</a>
        </h3>
        <h3 style={{ marginLeft: "calc(2vw - (-0.5vmin))", marginBottom: "0px" }}>
          <a
            href="https://codafanzine.bandcamp.com/merch/sps-a-sky-like-that"
            target="_blank" rel="noreferrer"
          >
            SHOP
          </a>
        </h3>
      </div>
    </div>
    
      <img
        src="/images/about/sps/sps_foto.png"
        className={styles.imageUser}
        alt=""
      />

  </div>
);

const Post = (props) => (
  <div style={{ textAlign: "center"}}>
    <img src={props.image} alt="" />
  </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      {data.allSpsJson.edges.map(({ node }) => (
        <Post image={node.image} />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allSpsJson {
      edges {
        node {
          image
        }
      }
    }
  }
`;
